
.image-title{
    margin: 20px 10px ;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: bold;
}

.feedbackImages{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.feedbackImages img{
    max-width: 300px;
    width: 300px;
    margin: 20px;
}
