.directionDescWrapper{
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
}
.colorName {
    text-align: center;
    width: 130px;
    min-height: 20px;
    color: #000;
}
.colorCode {
    text-align: center;
    width: 130px;
    height: 20px;
    border: 1px solid #000;
}
.colorCodeForСrystal {
    text-align: center;
    width: 130px;
    height: 20px;
    border: 1px solid #000;
    background:-moz-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EE82EE', endColorstr='#FFFF00', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

}

.crystalTable {
    background:-moz-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EE82EE', endColorstr='#FFFF00', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(238, 130, 238, 1) 3%, rgba(0, 188, 212, 1) 4%, rgba(238, 130, 238, 1) 9%, rgba(255, 255, 0, 1) 14%, rgba(238, 130, 238, 1) 17%, rgba(0, 188, 212, 1) 19%, rgba(255, 255, 0, 1) 22%, rgba(238, 130, 238, 1) 26%, rgba(0, 188, 212, 1) 28%, rgba(255, 255, 0, 1) 31%, rgba(238, 130, 238, 1) 33%, rgba(0, 188, 212, 1) 35%, rgba(255, 255, 0, 1) 37%, rgba(0, 188, 212, 1) 40%, rgba(255, 255, 0, 1) 42%, rgba(238, 130, 238, 1) 45%, rgba(255, 255, 0, 1) 47%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 53%, rgba(255, 255, 0, 1) 56%, rgba(238, 130, 238, 1) 58%, rgba(0, 188, 212, 1) 60%, rgba(238, 130, 238, 1) 63%, rgba(255, 255, 0, 1) 65%, rgba(238, 130, 238, 1) 69%, rgba(0, 188, 212, 1) 72%, rgba(238, 130, 238, 1) 74%, rgba(255, 255, 0, 1) 76%, rgba(238, 130, 238, 1) 79%, rgba(0, 188, 212, 1) 82%, rgba(255, 255, 0, 1) 85%, rgba(238, 130, 238, 1) 87%, rgba(0, 188, 212, 1) 89%, rgba(0, 188, 212, 1) 93%, rgba(255, 255, 0, 1) 96%, rgba(238, 130, 238, 1) 99%);

}
